<template>
  <v-container
    class="content-standard"
    bg
    fill-height
    grid-list-md
    text-xs-center
  >
    <v-layout row wrap align-center>
      <v-flex>
        <vue-plyr controls="false" loop="true">
          <div ref="ss" data-plyr-provider="vimeo" data-plyr-embed-id="618194817"></div>
        </vue-plyr>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ScreenSaver",
  data: () => ({
    events: ["play", "pause"],
    eventQueue: [],
    eventBufferMilliseconds: 750,
    lastEmit: 0
  }),
  methods: {
    play() {
      if (this.$refs.ss && this.$refs.ss.plyr) {
        this.$refs.ss.plyr.play();
        setTimeout(() => {
          this.$refs.ss.plyr.loop = true;
          this.$refs.ss.plyr.muted = true;
        }, 1);
      } else {
        setTimeout(this.play, 250);
      }
    }
  },
  mounted() {
    window.$ss = this;
    this.play();
  }
};
</script>

<style lang="scss">
.plyr__control[data-plyr="mute"],
.plyr__control[data-plyr="pip"],
.plyr__volume {
  display: none !important;
}
.plyr__time {
  margin: 0 8px;
}
#screen-saver {
  .plyr__controls {
    display: none !important;
  }
}
</style>
